<template>
  <div class="c-licensing-paragraph" v-for="item of content" :key="item.title">
    <p class="paragraph-title">{{ item.title }}</p>
    <p class="paragraph-content" v-for="c of item.content" :key="c">{{ c }}</p>
  </div>
</template>
<script>
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'LicensingParagraph',
  props: {
    content: {
      type: Array,
      default: () => [],
    },
  },
});
</script>
<style lang="scss" scoped>
.c-licensing-paragraph {
  &+& {
    margin-top: 60px;
  }
}
.paragraph-title {
  @include font-style($c-black, 18, normal, 0px);
}

.paragraph-content {
  @include font-style($c-black, 18, normal, 0px);
}
</style>
