<template>
  <div class="c-licensing-content">
    <div class="g-content">
      <mi-title :title="title"></mi-title>

      <div class="main">
        <div class="main__content">
          <licensing-paragraph :content="content"></licensing-paragraph>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { defineComponent } from 'vue';
import MiTitle from '@/components/Global/MiTitle.vue';
import LicensingParagraph from '@/components/Licensing/LicensingParagraph.vue';

export default defineComponent({
  name: 'LicensingContent',
  components: {
    MiTitle,
    LicensingParagraph,
  },
  props: {
    title: {
      type: String,
      defualt: '',
    },
    content: {
      type: Array,
      default: () => [],
    },
  },
});
</script>

<style lang="scss" scoped>
.c-licensing-content {
  padding-top: 60px;
  padding-bottom: 84px;
  background-color: $c-white3;
}

.main {
  background-color: $c-white;
  border-radius: 10px;
  padding: 40px 0 62px;
  position: relative;
  box-shadow: 0px 0px 10px rgba($c-black, 0.05);
  background-image: url('~@/assets/licensing/img_deco.png');
  background-size: 300px;
  background-repeat: no-repeat;
  background-position: bottom right;

  &:before {
    content: '';
    height: 10px;
    width: 100%;
    display: inline-block;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    @include position(tl, 0, 0);
    @extend %bg-gradient-90deg;
  }

  &__content {
    @include max-width(800);
  }
}

@media screen and (min-width: $tablet) {
  .c-licensing-content {
    padding-top: 80px;
    padding-bottom: 150px;
  }
  .main {
    padding: 115px 0 138px;
    background-size: 609px;

    &__content {
      @include max-width(800);
    }
  }
}
</style>
